<template>
  <div class="row">
    <div class="col-12">
      <ibox :title="$t('statistic_product')">
        <template slot="content" slot-scope="{ setLoading }">
          <ProductsSale @loading="setLoading" />
        </template>
      </ibox>
    </div>
  </div>
</template>

<script>
import Ibox from "@/components/base/Ibox.vue";
import ProductsSale from "@/components/Statistics/Sales/Products/ProductsSale.vue";

export default {
  name: "ProductsSaleStatistics",
  components: {
    Ibox,
    ProductsSale
  },
  data() {
    return {};
  }
};
</script>
