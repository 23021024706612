<template>
  <div class="row">
    <div class="col">
      <form>
        <div
          class="form-group row"
          :class="{ 'has-error': errors.has('productId') }"
        >
          <div class="col-12 col-md-4">
            <label>
              {{ $t("products") }}
            </label>
            <v-select
              name="productId"
              v-model="selectedProductId"
              v-validate="'required'"
              label="name"
              :options="productList"
              :reduce="product => product.id"
              @input="changeData()"
            ></v-select>
            <small v-show="errors.has('productId')" class="text-danger">
              {{ $t("field_required") }}
            </small>
          </div>
          <div
            class="col-12 col-md-4"
            :class="{ 'has-error': errors.has('date_start') }"
          >
            <label>
              {{ $t("start_date") }}
            </label>
            <date-picker
              class="NeoDataPicker"
              v-model="dateStart"
              :config="optionsDate"
              name="date_start"
              autocomplete="off"
              v-validate="'required'"
              @input="changeData()"
            />
            <small v-show="errors.has('date_start')" class="text-danger">
              {{ $t("field_required") }}
            </small>
          </div>
          <div
            class="col-12 col-md-4"
            :class="{ 'has-error': errors.has('date_end') }"
          >
            <label>
              {{ $t("end_date") }}
            </label>
            <date-picker
              class="NeoDataPicker"
              v-model="dateEnd"
              :config="optionsDate"
              name="date_end"
              autocomplete="off"
              v-validate="'required'"
              @input="changeData()"
            />
            <small v-show="errors.has('date_end')" class="text-danger">
              {{ $t("field_required") }}
            </small>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div
              class="btn btn-primary my-1 ml-1 d-block d-md-inline-block"
              @click="setTime(30)"
            >
              {{ $t("last_month") }}
            </div>
            <div
              class="btn btn-primary my-1 ml-1 d-block d-md-inline-block"
              @click="setTime(90)"
            >
              {{ $t("last_3_months") }}
            </div>
            <div
              class="btn btn-primary my-1 ml-1 d-block d-md-inline-block"
              @click="setTime(180)"
            >
              {{ $t("last_6_months") }}
            </div>
            <div
              class="btn btn-primary my-1 ml-1 d-block d-md-inline-block"
              @click="setTime(365)"
            >
              {{ $t("last_12_months") }}
            </div>
          </div>
        </div>

        <div class="hr-line-dashed"></div>
        <div class="form-group row">
          <div class="col-sm-4 col-sm-offset-2">
            <a class="btn btn-primary btn-sm text-white" @click="handleSubmit">
              {{ $t("download_data") }}
            </a>
          </div>
        </div>
      </form>
      <div class="hr-line-dashed"></div>
      <div v-show="productsStatusStatistic.length > 0" class="form-group row">
        <div class="col-12">
          <span>
            <h2>{{ $t("statistic_product") }}</h2>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Łącznie zamówień</th>
                  <th>Łączna kwota</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(s, i) in productsStatusStatistic" :key="i">
                  <td>{{ s.name_pl }}</td>
                  <td>{{ s.sum_quantity }}</td>
                  <td>{{ s.sum_amount }}</td>
                </tr>
              </tbody>
            </table>
          </span>
        </div>
        <canvas id="product-chart"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
export default {
  name: "ProductsSaleForm",
  data() {
    return {
      selectedProductId: null,
      productList: [],
      dateStart: "",
      dateEnd: "",
      reqStatus: "all",
      productsChart: null,
      productsStatusStatistic: []
    };
  },
  computed: {
    urlProductList() {
      return `${this.$route.params.shop_name}/${this.$route.params.shop_lang}/lists/products`;
    },
    urlStatistic() {
      return `${this.$route.params.shop_name}/${this.$route.params.shop_lang}/statistics/sales/products?productId=${this.selectedProductId}&status=${this.reqStatus}&dateStart=${this.dateStart}&dateEnd=${this.dateEnd}`;
    }
  },
  async created() {
    await this.getProductsList();
    this.dateEnd = this.$moment().format("YYYY-MM-DD");
    this.dateStart = this.$moment(this.dateEnd)
      .subtract(1, "months")
      .format("YYYY-MM-DD");
    this.$emit("loading", false);
  },
  methods: {
    setTime(time) {
      let months = Math.round(time / 30);
      this.dateEnd = this.$moment().format("YYYY-MM-DD");
      this.dateStart = this.$moment(this.dateEnd)
        .subtract(months, "months")
        .format("YYYY-MM-DD");
    },
    getProductsList() {
      return this.$http.get(this.urlProductList).then(resp => {
        if (resp.status === 200) {
          this.productList = resp.data.data;
          return true;
        }
      });
    },
    changeData() {
      this.productsStatusStatistic = [];
    },
    createChart(chartId, chartData) {
      const ctx = document.getElementById(chartId);
      let chartStatus = Chart.getChart(`${chartId}`);
      if (chartStatus != undefined) {
        chartStatus.destroy();
      }
      let myChart = new Chart(ctx, {
        type: chartData.type,
        data: chartData.data,
        options: chartData.options
      });
      this.$emit("loading", false);
      return myChart;
    },
    getRandomRGB() {
      return 0 + Math.floor((255 - 0) * Math.random());
    },
    getLabel() {
      return this.productList.find(
        x => parseInt(x.id) === parseInt(this.selectedProductId)
      ).name;
    },
    prepareDataToChart(products) {
      const labels = products.data.quantity.map(value => value.date);

      const dataset = [];
      dataset.push({
        label: this.getLabel(),
        data: products.data.quantity.map(value => value.quantity),
        backgroundColor: [`rgba(210, 69, 64, 0.2)`],
        borderColor: [`rgba(210, 69, 64, 1)`],
        borderWidth: 3
      });

      return {
        type: "line",
        data: {
          labels: labels,
          datasets: dataset
        },
        options: {
          responsive: true,
          lineTension: 0.3,
          scales: {
            y: {
              ticks: {
                beginAtZero: true,
                padding: 25
              }
            }
          }
        }
      };
    },
    getStatisticsProduct() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$emit("loading", true);
          let date1 = new Date(this.dateStart);
          let date2 = new Date(this.dateEnd);
          let difInMs = date2.getTime() - date1.getTime();
          this.limitDay = difInMs / (1000 * 3600 * 24);
          this.$http
            .get(this.urlStatistic)
            .then(resp => {
              if (
                resp.data.data.quantity.length < 1 ||
                resp.data.data.status.length < 1
              ) {
                this.$toastr.error("Brak zamówień dla wybranego kryterium");
                this.$emit("loading", false);
                return false;
              }
              this.productsStatusStatistic = resp.data.data.status;
              this.prepareDataToChart(resp.data);
              this.productsChart = this.createChart(
                "product-chart",
                this.prepareDataToChart(resp.data)
              );
              this.$emit("loading", false);
            })
            .catch(() => {
              this.$emit("loading", false);
              this.$toastr.error("Błąd podczas pobierania danych");
            });
        }
      });
    },
    handleSubmit() {
      this.getStatisticsProduct();
    }
  }
};
</script>
